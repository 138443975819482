window.IntegrationsAppConfig = {
  ENVIRONMENT: "#{environment}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  INTEGRATIONS_API_URL: "#{integrationsApiUrl}#",
  CLIENT_CREDENTIALS_API_URL: "#{clientCredentialsApiUrl}#",
  PORTAL_URL: "#{portalUrl}#",
  PORTAL_API_URL: "#{portalApiUrl}#",
  CONNECTORS_API_URL: "#{connectorsApiUrl}#",
  NCINO_API_URL: "#{nCinoApiUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
};
